import React from 'react'
import ReactDOM from 'react-dom'

import { Entry } from 'Pages/root/routes'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CONTOUR, getContour } from '@pp/axios'

Sentry.init({
	dsn:
		getContour() === CONTOUR.UAT
			? 'https://64d2525c625a4df5bc9fb6fda37ccfff@sentry.dev.dasreda.ru/21'
			: null,
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0.2,
	maxBreadcrumbs: 10,
	denyUrls: ['localhost'],
	sendClientReports: false,
})

// Load svg sprite with icons
require.context('../assets/svg/', false, /\.(svg)$/)
window.addEventListener('DOMContentLoaded', () => {
	fetch('/sprite.svg')
		.then((response) => response.text())
		.then((data) => {
			const div = document.createElement('div')
			div.innerHTML = data
			div.style.display = 'none'
			document.body.appendChild(div)
		})
		// eslint-disable-next-line
		.catch(() => {})
})

// const server = new WebSocket(
// 	`wss://ppapi.dev.dasreda.ru/cable?ticket=ca7c462a54936d848eccc4a945bc36a24153f7bdb00aa1e0bb23b8be9367ccfc`
// )
// server.onopen = e => {
// 	alert('[open] Соединение установлено')
// 	console.log(e)
// }
// server.onclose = e => {
// 	console.log(e.code)
// 	console.log(e.reason)
// 	alert('[open] Соединение закрыто')
// }
// server.onmessage = ({ data }) => {
// 	alert(data)
// }

ReactDOM.render(<Entry />, document.getElementById('root'))

Set.prototype.toJSON = function setToJSON() {
	return [...this]
}

Map.prototype.toJSON = function mapToJSON() {
	const obj = {}
	this.forEach((value, key) => {
		obj[key] = value
	})
	return obj
}

// contour is needed for @pp/axios (axiosDS)
// @ts-ignore
window.localStorage.setItem('contour', process.env.CONTOUR)
