import React, { FC, useState } from 'react'
import { matchPath } from 'react-router-dom'
import { useEffectOnce } from 'Src/hooks/useEffectOnce'
import { EntryRouteProps } from './EntryRoute'

export const EntryRouter: FC = ({ children }) => {
	const [, setCurrentPathname] = useState<string>(window.location.pathname)

	useEffectOnce(() => {
		const handleLocationChange = () => setCurrentPathname(window.location.pathname)

		const { history } = window
		const { pushState, replaceState } = history

		history.pushState = new Proxy(pushState, {
			apply(target, thisArg, args) {
				target.apply(thisArg, args)
				handleLocationChange()
			},
		})
		history.replaceState = new Proxy(replaceState, {
			apply(target, thisArg, args) {
				target.apply(thisArg, args)
				handleLocationChange()
			},
		})

		window.addEventListener('popstate', () => {
			handleLocationChange()
		})
	})

	return (React.Children.toArray(children) as React.ReactElement<EntryRouteProps>[]).find((child) =>
		matchPath(window.location.pathname, child.props.path)
	)
}
