import React, { FC, Suspense } from 'react'

export type EntryRouteProps = {
	lazyComponent: ReturnType<typeof React.lazy>
	path: string
}

export const EntryRoute: FC<EntryRouteProps> = ({ lazyComponent: LazyComponent }) => (
	<Suspense fallback={null}>
		<LazyComponent />
	</Suspense>
)
