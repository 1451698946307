const WHITE_LIST_URLS = ['/test', '/neo_library', '/profile', '/auth/sbbol', '/bank_employees']
const FrontUrls = {
	home: '/home',
	dashboard: '/dashboard',
	prelogin: '/prelogin',
	users: '/grid/user',
	partners: '/grid/partner',
	customer_identification: '/grid/customer_identification',
	ast_partner_requests: '/grid/ast_partner_requests',
	bank_employees: '/bank_employees',
	new_acts: '/new_acts',
	access: '/access',
	partner_profile: '/partner_profile',
	archive_orders: '/archive_brokers',
	expantion_sms: '/expantion_sms',
	neo_library: '/neo_library',
	customer_identification_logs: '/customer_identification/logs/:customer_id',
	test: '/test',
	profile: '/profile',
	root: '/',
	partner_registration: '/registry/:token',
	cabinet_payment_callback: '/rbidos_payment_callback',
	coliseum: {
		index: '/coliseum',
		call: '/coliseum/call',
		block: '/coliseum/journal_block',
		blacklist: '/coliseum/black_list',
		settings: '/coliseum/settings',
	},
	orders: {
		orders: '/orders',
		products: '/orders/products',
		merchant_branches: '/orders/merchant_branches',
		block: '/orders/block',
		blocking_rule: '/orders/blocking_rule',
		unblock_requests: '/orders/unblock_requests',
		block_product_groups: '/orders/block_product_groups',
		mass_assign_products: '/orders/mass_assign_products',
		externalForms: '/ext_forms',
		recommendations: '/orders/recommendations',
		recommendation_create: '/orders/recommendations/create',
		recommendation_edit: '/orders/recommendations/edit/:id',
		businessapps: '/orders/businessapps',
		businessapps_create: '/orders/businessapps/create',
		businessapps_edit: '/orders/businessapps/edit/:id',
	},
	broker: {
		index: '/ibro',
		logs: '/ibro/logs/:id',
		limits: '/ibro/limit_requests',
		limits_create: '/ibro/limit_requests/create',
		limits_edit: '/ibro/limit_requests/edit/:id',
		settings: '/ibro/settings',
		index_neo: '/ibro_neo',
		requests: '/ibro_neo/requests',
		requests_create: '/ibro_neo/requests/create',
		requests_edit: '/ibro_neo/requests/edit/:id',
		download_file: '/ibro_neo/download_file',
	},
	partner_request: {
		index: '/partner_request',
		edit: '/partner_request/edit/:id',
		create: '/partner_request/create',
	},
	cabinet_redesign: {
		index: '/registry_business',
		cabinet: '/registry_business/registration',
		order_create: '/registry_business/registration/create',
		order_edit: '/registry_business/registration/edit/:id',
		logs: '/registry_business/registration/logs/:customer_id',
		partners_personal_account: '/registry_business/partners_personal_account',
		company: '/registry_business/company',
		lead_pro: '/registry_business/lead_pro',
		partner_infotecs_request_registries: '/registry_business/partner_infotecs_request_registries',
		change: '/registry_business/change',
		change_edit: '/registry_business/change/edit/:id',
		registry_business_access_neo_index: '/registry_business/registry_business_access_neo',
		registry_business_access_neo_create:
			'/registry_business/registry_business_access_neo/create/:id',
		registry_business_access_neo_create_agent:
			'/registry_business/registry_business_access_neo/create_agent',
		registry_business_access_neo_modal:
			'/registry_business/registry_business_access_neo/create_agent_modal',
		registry_business_access_neo_order_agents:
			'/registry_business/registry_business_access_neo/agents/:id',
		registry_business_access_neo_logs: '/registry_business/registry_business_access_neo/logs/:id',
		registry_business_access_neo_edit: '/registry_business/registry_business_access_neo/edit/:id',
	},

	cabinet_redesign_mobile: {
		filters: '/registry_business/registration/filters',
		report: '/registry_business/registration/report',
		info: '/registry_business/registration/info/:id',
		draft_page: '/registry_business/registration/draft/:id',
		create: '/registry_business/registration/create',
	},
	pp: {
		index: '/pp',
		matrix_role: '/pp/matrix_role',
		mass_use_merchant_form: '/pp/mass_use_merchant_form',
		mass_update_curator_form: '/pp/mass_update_curator_form',
		department: '/pp/department',
		department_edit: '/pp/department/edit/:id',
		department_create: '/pp/department/create',
		contracts_registry: '/pp/contracts_registry',
		black_list_partner: '/pp/black_list_partners',
		partner: '/pp/partner',
		customer_identification_auth: '/pp/customer_identification_auth',
		affiliate_program_reports: '/pp/affiliate_program_reports',
	},
	referenceBook: {
		index: '/reference_book',
		gosb: '/reference_book/gosb',
		territorial_bank: '/reference_book/territorial_bank',
		sector: '/reference_book/sector',
		channel_structure: '/reference_book/channel_structure',
		special_offers: '/reference_book/special_offers',
		channel: '/reference_book/channel',
		cities: '/reference_book/cities',
		regions: '/reference_book/regions',
	},
	admin_panel: {
		index: '/admin_panel',
		sms_settings: '/admin_panel/sms_settings',
		sms: '/admin_panel/sms',
		monitoring: '/admin_panel/monitoring',
		tags: '/admin_panel/tags',
		template: '/admin_panel/template',
		template_edit: '/admin_panel/template/edit/:id',
		notifications: '/admin_panel/notifications',
		notifications_edit: '/admin_panel/notifications/edit/:id',
		notifications_create: '/admin_panel/notifications/create',
		setting: '/admin_panel/setting',
		product_order_statuses: '/admin_panel/product_order_statuses',
		fake_responses_index: '/admin_panel/fake_responses',
		fake_responses_dadata_suggest_by_id_party:
			'/admin_panel/fake_responses/dadata_suggest_by_id_party',
		fake_responses_dadata_suggest_drs: '/admin_panel/fake_responses/reserve_accounts',
		fake_responses_rbidos_load_company: '/admin_panel/fake_responses/rbidos_load_company',
		landings: '/admin_panel/landings',
		landings_edit: '/admin_panel/landings/edit/:id',
		landings_create: '/admin_panel/landings/create',
		pop_up: '/admin_panel/pop_up',
		lead: '/admin_panel/lead',
		lead_edit: '/admin_panel/lead/edit/:id',
		lead_only_view: '/admin_panel/lead/only_view/:id',
		merchant_status: '/admin_panel/merchant_status_mappings',
		merchant_status_edit: '/admin_panel/merchant_status_mappings/edit/:id',
		merchant_status_create: '/admin_panel/merchant_status_mappings/create',
		dashboard_holidays: '/admin_panel/dashboard_holidays',
	},
	advertisment: {
		index: '/ads',
		organization_create: '/ads/organization/create',
		contract_create: '/ads/contract/create',
		creative_create: '/ads/creative/create',
		report_create: '/ads/report/create',
		organization_edit: '/ads/organization/edit/:id/',
		contract_edit: '/ads/contract/edit/:id',
		creative_edit: '/ads/creative/edit/:id',
		report_edit: '/ads/report/edit/:id',
		logs: '/ads/:tab/:id/logs',
	},
	act_mobile: {
		filter: '/new_acts/filters',
		info: '/new_acts/info/:id',
		sign: '/new_acts/sign/:id',
		cancel: '/new_acts/cancel/:id',
	},
	logs: {
		index: '/logs',
		partners: '/logs/partners',
		users: '/logs/users',
		orders: '/logs/orders',
		new_orders: '/logs/new_orders',
		customer_identification: '/logs/customer_identification',
		reports: '/logs/reports',
		rbidos_request: '/logs/rbidos_request',
		status_upload: '/logs/status_upload',
		sbercrm: '/logs/sbercrm',
		sms_event_logs: '/logs/sms_event_logs',
		change_status_error: '/logs/change_status_error',
		orders_kes: '/logs/orders_kes/:id',
		bg_ast_registration: '/logs/bg_ast_registration',
		bg_request_status_changes: '/logs/bg_request_status_changes',
		bg_events: '/logs/bg_events_logs',
		online_orders: '/logs/online_orders',
		reserve_accounts: '/logs/reserve_accounts',
		acts: '/logs/act_status_changes',
		orders_api: '/logs/new_orders_api',
		knowledge_events: '/logs/knowledge_events',
		qa_requests: '/logs/qa_requests',
		order_api_requests: '/logs/order_api_request_log',
		integration_channel: '/logs/rbidos_intagration_channel_logs',
		external_requests: '/logs/call_easy_logs',
		departments: '/logs/department',
		pre_record: '/logs/cm_logs',
		partner_agent_events: '/logs/partner_agent_events',
		bprofile: '/logs/bprofile',
	},
	survey: {
		index: '/survey',
		survey_short_link: '/survey/view/:id',
		survey_create: '/survey/create',
		survey_edit: '/survey/edit/:id',
		survey_view_question: '/survey/:id/view/question/:question_id',
		survey_answers: '/survey/answers/:id',
		survey_complete: '/survey/complete',
	},
	knowledge: {
		index: '/knowledge',
		project_create: '/knowledge/project/edit',
		admin_access: '/knowledge/admin/access',
		project_edit: '/knowledge/project/:id/edit',
		article_project_create: '/knowledge/project/:id/article/edit',
		article_create: '/knowledge/project/article/edit',
		article_edit: '/knowledge/project/:idProject/article/:id/edit',
		article_preview: '/knowledge/project/:idProject/article/:idArticle/preview',
		project_view: '/knowledge/project/:slugProject',
		article_view: '/knowledge/:slugProject/:slugArticle',
		article_search: '/knowledge/search',
		video_create: '/knowledge/project/video/edit',
		video_edit: '/knowledge/project/:idProject/video/:id/edit',
		project_index: '/knowledge/project',
	},
	auth: {
		index: '/auth',
		reset: '/auth/reset',
		sbbol: '/auth/sbbol',
		login: '/auth/login',
		reset_password_by_token: '/auth/reset_password_by_token',
	},
	landing: {
		self_employed: '/landing/self-employed',
		credits: '/landing/credits',
		info: '/info',
		salary_project: '/landing/salary-project',
		acquiring: '/landing/acquiring',
		notary: '/landing/notary',
		product: '/landing/products',
		partner_form: '/landing/partner-form',
		multi: '/landing/multi',
		index: '/landing',
	},
	rlead: {
		public: '/rlead/:token',
		with_sber_id: '/with_sber_id',
		upload_document: '/upload/:token',
		registration: '/rlead_registration',
		create: '/rlead_create',
		document: '/rlead_document/:document',
		error: '/rlead_error/:errorCode',
		success: '/rlead_success',
		document_attachment: '/document_attachment',
		answer_sber: '/sberid/openid_connect/callback',
	},
	partner_certificate: '/partner_certificate',
	pages: {
		page_403: '/page_403',
		page_404: '/page_404',
		page_500: '/page_500',
	},

	orders_redesign: {
		index: '/orders_redesign',
		create: '/orders_redesign/create',
		edit: '/orders_redesign/edit/:id',
		stats: '/orders_redesign/order_task_stats',
	},
	orders_redesign_mobile: {
		filters: '/orders_redesign/filters',
		report: '/orders_redesign/report',
		view: '/orders_redesign/view/:id',
		check_inn: '/orders_redesign/create/inn',
		agreement: '/orders_redesign/create/agreement',
		product: '/orders_redesign/create/products',
		cart: '/orders_redesign/create/products/cart',
		form: '/orders_redesign/create/edit',
		created: '/orders_redesign/create/created',
		reserve: '/orders_redesign/reserve/:id?',
		completed: '/orders_redesign/create/agreement/completed',
	},
}

const IgnoreLocation = new Set([FrontUrls.root, FrontUrls.auth.index])
const NOT_FOR_REDIRECT_URLS = new Set([
	FrontUrls.auth.index,
	FrontUrls.pages.page_403,
	FrontUrls.pages.page_404,
	FrontUrls.pages.page_500,
])

const HideFooter = [
	FrontUrls.admin_panel.notifications,
	FrontUrls.broker.limits,
	FrontUrls.broker.index_neo,
	FrontUrls.cabinet_redesign.cabinet,
]

export { WHITE_LIST_URLS, FrontUrls, IgnoreLocation, NOT_FOR_REDIRECT_URLS, HideFooter }
