import React, { FC, lazy } from 'react'
import { FrontUrls } from 'Constants/FrontUrls'
import { EntryRouter, EntryRoute } from 'Pages/root'

const RootLazy = lazy(() => import('Pages/root/components/Root/index'))
const LeadRoot = lazy(() => import('Pages/leadNeo/root/Root'))
const Landings = lazy(() => import('Pages/landings/routes'))
const PartnerCertificateRoot = lazy(() => import('Pages/partnerCertificate/root/Root'))

export const Entry: FC = () => {
	return (
		<EntryRouter>
			<EntryRoute
				lazyComponent={Landings}
				// @ts-ignore
				path={[
					FrontUrls.landing.credits,
					FrontUrls.landing.self_employed,
					FrontUrls.landing.salary_project,
					FrontUrls.landing.acquiring,
					FrontUrls.landing.notary,
					FrontUrls.landing.partner_form,
					FrontUrls.landing.info,
					FrontUrls.landing.product,
					FrontUrls.landing.multi,
				]}
			/>
			<EntryRoute
				lazyComponent={LeadRoot}
				// @ts-ignore
				path={[
					FrontUrls.rlead.answer_sber,
					FrontUrls.rlead.public,
					FrontUrls.rlead.with_sber_id,
					FrontUrls.rlead.error,
					FrontUrls.rlead.upload_document,
					FrontUrls.rlead.registration,
					FrontUrls.rlead.create,
					FrontUrls.rlead.document,
					FrontUrls.rlead.success,
					FrontUrls.rlead.document_attachment,
				]}
			/>
			<EntryRoute
				lazyComponent={PartnerCertificateRoot}
				// @ts-ignore
				path={[FrontUrls.partner_certificate]}
			/>
			<EntryRoute lazyComponent={RootLazy} path="*" />
		</EntryRouter>
	)
}
